import React, { useState, useEffect, Fragment } from "react";
import {
  HeroImage,
  HeroContainer,
  HeroImageContainer,
  IntroColumns,
  Role,
  RoleHeading,
  RoleSubHeading,
  VisitHeading,
  VisitSubHeading,
  TimeHeading,
  TimeSubHeading,
  About,
  AboutHeading,
  InsightContainer,
  InsightHeading,
  InsightSubHeading,
  FooterSection,
  FooterHeading,
} from "./styles";

import NibiFirst from "../../assets/Nibi.png";
import NibiSecond from "../../assets/NibiImageTwo.png";
import NibiThird from "../../assets/NibiImageThree.png";
import NibiFour from "../../assets/NibiImageFour.png";
import NibiFive from "../../assets/NibiSketch.png";
import NibiSix from "../../assets/NibiResearch.png";

import GiddtUpFirst from "../../assets/giddyupone.png";
import GiddtUpSecond from "../../assets/giddyuptwo.png";
import GiddtUpThird from "../../assets/giddyupthree.png";
import GiddtUpFour from "../../assets/giddyupfour.png";
import GiddtUpFive from "../../assets/giddyupfive.png";
import GiddtUpSix from "../../assets/giddyupsix.png";

import KonradOne from "../../assets/konradone.png";
import KonradTwo from "../../assets/konradtwo.png";
import KonradThree from "../../assets/konradthree.png";
import KonradFour from "../../assets/konradfour.png";
import KonradFive from "../../assets/konradfive.png";
import KonradSix from "../../assets/konradsix.png";


import { Reorder } from "framer-motion";
import { Item } from "./Item.tsx";
import Section from "./Section";

import { useParams } from "react-router-dom";

//Router
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import "./styles.css";

const projects = [
  {
    id: "1",
    slug: "Konrad",
    hasTechSection: true,
    herotextOne: `Konrad Group Internship 2023`,
    heroSubHeading: `Whose playing pool next?`,
    role: ` Intern UI/UX Designer`,
    url: "https://www.konrad.com",
    year: "2023",
    intro:
      "During my internship at Konrad Group, I had the opportunity to work on a transformative design sprint for a major global professional services firm. The project focused on enhancing the search functionality of an internal knowledge management system used by hundreds of thousands of professionals worldwide across over 150 countries. Our team developed a proof of concept (POC) that aimed to revolutionize how the firm's employees access and utilize their vast repository of internal knowledge. The design sprint challenged us to leverage AI technology to dramatically improve search efficiency and effectiveness within their system. The outcome of our design sprint was highly successful, with the client expressing enthusiasm for our proposed solution. They indicated plans to further develop and implement our concepts into their global knowledge management system in the future, potentially impacting the daily work of their entire workforce. This project was a highlight of my internship, but it was just one of several exciting initiatives I was involved in at Konrad Group. My experience was diverse and fast-paced, exposing me to various aspects of UX/UI design across different industries and project types. From concept development to user research and prototyping, I gained hands-on experience in tackling real-world design challenges. While I can't disclose specific details due to confidentiality agreements, I'd be happy to discuss my experiences, the methodologies I used, and the valuable lessons I learned during the interview process.",
    skills: ["Figma 🔨", "Communication 🎙️",],
    introductionImage: KonradOne,
    segwayImage: KonradThree,
    challengeImage: KonradFour,
    solutionImage: KonradTwo,
    methodImage: KonradSix,
    researchImage: KonradFive,
    ndaDisclaimer: "Please note: The designs you are about to view have been white-labelled to protect the client's identity due to NDA restrictions.",
  },
  {
    id: "2",
    slug: "GiddyUp",
    hasTechSection: true,
    herotextOne: `Where Fantasy Champions Are Made`,
    heroSubHeading: `If You Ain't First, You're Last`,
    role: ` CEO`,
    url: "https://giddyupsports.com/",
    year: "2023 - Current",
    intro:
      "GiddyUp Sports is an automated fantasy sports tool that revolutionizes waiver wire management in fantasy leagues. It allows users to set player preferences and automatically executes pickups when players clear waivers, eliminating late-night stress and early morning rushes. Compatible with Yahoo Fantasy Sports across NFL, MLB, NBA, and NHL leagues, GiddyUp Sports helps users build stronger teams consistently throughout the season. By automating tedious aspects of fantasy management, it saves time and provides a competitive edge, especially in leagues where waiver priority is crucial.",
    skills: ["AWS Lambda", "AWS SQS", "AWS EC2", , "AWS API Gateway", "Yahoo Fantasy API", "Python", "MongoDB", "NextJS", "Vercel", "Resend", , "Stripe"],
    ideationTopRightText: `A custom-designed mesh textile wraps the ear cushions to
    provide pillow-like softness while listening.`,
    ideationBottomRightText: `Crafted with acoustically engineered memory foam, the
    ear cushions gently create an immersive seal that is the
    foundation of incredible sound.`,
    challengeText: (
      <Fragment>
        As a die-hard fantasy sports fan, I kept running into a major problem that I knew was affecting tons of other players too. It all centered around the waiver wire - that crucial part of fantasy leagues where you can pick up free agents.
        Here's the deal: in Yahoo Fantasy leagues, waivers typically clear around 3 AM. This meant if you wanted the best shot at grabbing good players, you had two less-than-ideal options:
        <br />
        <br />
        1. Stay up super late
        <br />
        <br />
        2. Set an alarm to wake up in the middle of the night
        <br />
        <br />

        Neither of these was sustainable, especially for people with busy lives. I mean, who can consistently sacrifice sleep for fantasy sports? But if you didn't, you might miss out on players that could make or break your season.
        I saw this causing real issues:

        People were losing sleep
        It was affecting work and school performance
        It put people with demanding schedules at a disadvantage.

        Some tech-savvy players were trying to solve this with their own scripts or tools, but that required coding skills not everyone has. Plus, not everyone wants to leave their computer running all night.
        <br />
        <br />
        <i style={{ color: "#868686" }}>
        The more I dug into it, the more I realized this was a widespread issue. Fantasy sports is massive - it's a $20 billion industry with millions of players. Nearly 1 in 5 U.S. adults play fantasy sports, often investing $50 or more per league.
        </i>
        <br />
        <br />
        I kept thinking about people like busy parents, students with early classes, or professionals with demanding jobs. They love fantasy sports but can't realistically stay up all night for waivers. One mom told me, "As a busy mother of four, homeschooler, and business owner, finding time for fantasy football was nearly impossible. I used to sacrifice sleep just to stay competitive."
        <br />
        <br />
        <i style={{ color: "#868686" }}>
        So the challenge was clear: How could we make the waiver wire process accessible to everyone, regardless of their schedule, without compromising the competitive spirit of fantasy sports? That's the problem I set out to solve with GiddyUp Sports.
        </i>
      </Fragment>
    ),
    researchText: (
      <Fragment>
        Once I identified the challenge, I dove headfirst into research. I wanted to understand the full scope of the problem and see if anyone had tried to solve it before. First, I dug into the numbers. The fantasy sports industry has exploded in recent years, growing to a $20 billion market. Participation among U.S. adults jumped from 13% in 2014 to 19% in 2023. These weren't just casual players either - the average person was putting about $50 into each league, chasing prizes around $350. It was clear that people were seriously invested, both financially and emotionally.
        <br />
        <br />
        I also found that 97% of fantasy players were spending almost 7 hours a week on their teams during work hours. This showed just how much time and energy people were willing to put in, even when it wasn't convenient.
        Next, I looked into existing solutions. I came across a GitHub repo called "Free Agent Sleeper" by Philip Fong. It was a script designed to automate player additions and drops in fantasy football. While it was a step in the right direction, it had a major flaw - it required users to have coding knowledge and run their own servers. This wasn't accessible for the average fantasy sports fan.
        I also posted on Reddit to gauge interest and get feedback. The response was overwhelming - my post got 205k views, 547 upvotes, and 204 comments. People were clearly hungry for a solution.
        <br />
        <br />
        To get a more personal perspective, I reached out to fellow fantasy players. I heard stories from parents, students, and professionals who all loved the game but struggled with the time commitment. They wanted to stay competitive without sacrificing sleep or neglecting other responsibilities. <nbsp></nbsp>
        This research confirmed that there was a real need for an accessible, user-friendly solution that could automate the waiver wire process. It needed to be something that anyone could use, regardless of their technical skills or schedule. With this insight, I was ready to start building GiddyUp Sports.
        <br />
        <br />
      </Fragment>
    ),
    solutionText: (
      <Fragment>
        After thorough research and understanding the pain points of fantasy sports enthusiasts, I developed GiddyUp Sports. This tool is designed to automate the waiver wire process, allowing users to stay competitive without sacrificing sleep or disrupting their daily routines.
        <br />
        <br />
<b>Here's how GiddyUp Sports works:</b>
<br />
        <br />

<b>1. User Authentication:</b> Players start by logging in with their Yahoo Fantasy Sports credentials. This secure process gives GiddyUp access to the user's fantasy teams and league information.<br />
        <br />
<b>2. Team and Player Selection:</b> Once logged in, users can view their team rosters and available players in the waiver pool. They can then select which players they want to add to their team and which ones they're willing to drop.
<br />
        <br />
<b>3. Automated Waiver Claims:</b> Instead of staying up until 3 AM, users can set their desired player swaps in advance. GiddyUp then automatically executes these swaps when waivers clear, usually in the early morning hours.
<br />
        <br />
<b>4. Priority System:</b> Users can set priorities for their waiver claims, ensuring they get their top choices if multiple claims are successful.
<br />
        <br />
<b>5. Email Notifications:</b> After the waiver process runs, GiddyUp sends users an email notification detailing which claims were successful and which weren't.
<br />
        <br />
<b>6. User-Friendly Interface:</b> All of this is packaged in an intuitive, easy-to-use interface that doesn't require any technical knowledge to operate.
        <br />
        <br />
        The beauty of GiddyUp Sports is its simplicity from the user's perspective. Behind the scenes, it's handling complex processes, API calls, and timing issues. But for the user, it's as simple as picking players and waking up to see who they got.
        <br />
        <br />
        This solution levels the playing field for all fantasy sports enthusiasts. Whether you're a night owl, an early bird, or someone with a packed schedule, you now have an equal shot at nabbing those crucial free agents.
        GiddyUp Sports isn't about gaining an unfair advantage - it's about making fantasy sports more accessible and enjoyable for everyone, regardless of their real-life commitments.
      </Fragment>
    ),
    techText: (
      <Fragment>
        GiddyUp Sports is built on a robust, serverless architecture leveraging several AWS services. This approach ensures scalability, cost-effectiveness, and reliability. Let's break down the key components:
        <br />
        <br />
        <b>1. AWS Lambda:</b> The core of our serverless computing. We've developed several Lambda functions, each handling specific tasks:
        <br />
        <br />
yfa-data-sync: Synchronizes user data between Yahoo Fantasy and our database.
<br />
        <br />
yfa-process-swap: Executes the player swap requests when waivers clear.
<br />
        <br />
yfa-search-player: Handles player search functionality for users.
<br />
        <br />
yfa-get-team-roster: Retrieves up-to-date team roster information.
<br />
        <br />
yfa-authentication: Manages secure user authentication with the Yahoo Fantasy API.
<br />
        <br />
yfa-create-user-league: Sets up necessary data structures for new user leagues.
<br />
        <br />
yfa-manage-swap: Records user swap requests and queues them for processing.
<br />
        <br />
yfa-get-waiver: Checks current waiver wire status and available players.
<br />
        <br />
yfa-swap-players: Executes the actual API calls to perform player swaps.
<br />
        <br />
yfa-get-user-team: Retrieves all teams associated with a user across different leagues.
<br />
        <br />



These functions are written primarily in Python, with a few in Node.js for specific frontend interactions.
        <br />
        <br />
       <b>2. AWS API Gateway:</b> Acts as the front door for our API, routing requests to the appropriate Lambda functions. It handles authentication, throttling, and provides a uniform interface for our frontend to interact with our backend services.
        <br />
        <br />
       <b>3. AWS Simple Queue Service (SQS):</b> Manages the queue of swap requests. When users set up their desired player swaps, these requests are added to an SQS queue. This ensures that swaps are processed in the correct order and at the right time, even under high load.
        <br />
        <br />
        <b>4. MongoDB Atlas:</b> Our database solution, hosted on a serverless instance. We use several collections to manage user data, league information, and swap requests. The 'player_swaps' collection, for example, stores all the pending and completed swap requests.
        <br />
        <br />
        <b>5. NextJS Frontend:</b> Our user interface is built with NextJS and hosted on Vercel. This provides a fast, responsive experience for users across devices.
        <br />
        <br />
       <b>6. Yahoo Fantasy API:</b> We interact with this API to fetch league data, player statistics, and execute waiver claims. Our yfa-authentication Lambda function handles the OAuth flow, securely storing access and refresh tokens.
       <br />
        <br />
        <b>7. Resend:</b> Used for sending email notifications to users about their swap results.
        <br />
        <br />
        <b>8. Stripe:</b> Integrated for handling premium feature payments.
        <br />
        <br />
        <b>The process flow works like this:</b>
        <br />
        <br />
1. Users interact with our NextJS frontend, making API calls to our AWS API Gateway.
<br />
        <br />
2. API Gateway routes these requests to the appropriate Lambda function.
<br />
        <br />
3. Lambda functions interact with MongoDB Atlas and the Yahoo Fantasy API as needed.
<br />
        <br />
4. Swap requests are queued in SQS.
<br />
        <br />
5. A scheduled Lambda function (yfa-process-swap) runs when waivers clear, processing the queued swap requests.
Results are stored in MongoDB and email notifications are sent via Resend.
<br />
        <br />
        This architecture allows us to handle spikes in traffic (like when waivers clear) without maintaining constantly running servers. We only pay for the compute time we use, keeping costs low while ensuring high availability. This serverless, microservices-based architecture gives us the flexibility to quickly add new features and scale effortlessly as our user base grows.
      </Fragment>
    ),
    conclusionText: (
      <Fragment>
        GiddyUp Sports represents a significant leap forward in fantasy sports management. By leveraging cutting-edge serverless architecture and automation, we've addressed a long-standing pain point for fantasy enthusiasts. Our solution not only saves time and reduces stress but also levels the playing field, allowing users to compete effectively regardless of their schedules.
        <br />
        <br />
        As we look to the future, we're thrilled about the potential for GiddyUp Sports. We're continuously exploring ways to enhance our platform, including expanding to more sports leagues, implementing advanced analytics for smarter player recommendations, and developing features that further streamline the fantasy sports experience.
      </Fragment>
    ),
    introductionImage: GiddtUpFirst,
    segwayImage: GiddtUpSecond,
    challengeImage: GiddtUpThird,
    solutionImage: GiddtUpFour,
    methodImage: GiddtUpFive,
    researchImage: GiddtUpSix,
  },
  {
    id: "3",
    slug: "Nibì",
    herotextOne: `Everyone deserves clean water`,
    heroSubHeading: `Nibì: The Algonquian word for water`,
    role: ` Project Manager / User Experience Researcher / User Experience Designer`,
    // url: "wealthsimple.com/cash",
    year: "2022",
    intro:
      "Nibi is a tool that allows Indigenous communities to test and monitor the quality of their water, and share the results with relevant authorities in order to address any issues and improve water safety. It also aims to facilitate communication and collaboration between Indigenous communities, residents of Ontario, and the government or other charitable organizations.",
    skills: ["🧰 Figma", "🔨 Zotero", "🤠 University Project"],
    ideationTopRightText: `A custom-designed mesh textile wraps the ear cushions to
    provide pillow-like softness while listening.`,
    ideationBottomRightText: `Crafted with acoustically engineered memory foam, the
    ear cushions gently create an immersive seal that is the
    foundation of incredible sound.`,
    challengeText: (
      <Fragment>
        Access to safe drinking water is a basic human right and it is an
        essential component to human health and well-being. From a geological
        perspective, the more widely affected areas are the rural regions of the
        world, which due to their size and location often have little to no
        access to clean sanitized water. However, even Canada, despite being a
        large developed country that is richly endowed with water and possesses
        one of the largest renewable supplies of freshwater in the world, has
        also had struggles with maintaining accessibility to clean drinking
        water, especially within Indigenous reserves.
        <br />
        <br />
        <i style={{ color: "#868686" }}>
          First Nations reserves are ninety times more likely to be without
          running water compared to other Canadians. The number of water-borne
          infections in First Nations communities is also twenty-six times
          higher than the national average, and “about thirty percent of
          reserved-based community water systems are classified as posing a high
          risk to water quality”.
        </i>
        <br />
        <br />
        For many First Nations people, water encompasses a profound amount of
        cultural and spiritual significance. This powerful relationship to
        water, however, has deteriorated significantly, leading to water
        insecurities in many First Nation communities in Ontario. The primary
        purpose of this review is to ascertain why many First Nations
        communities have long been experiencing challenges to accessing safe
        drinking water. There are a large number of studies conducted on water
        parameters and trends within non-developed countries. However, our paper
        focuses on the current water conditions in the developed country of
        Canada, and more specifically follows a scope that reviews First Nations
        communities in the province of Ontario. This paper analyses why these
        challenges are heightened and provides insights into the current
        comprehensive review of the Canadian Drinking Water Guidelines,
        examination of safe drinking water in Canada, First Nations perspectives
        and emerging water quality technologies.
        <br />
        <br />
        <i style={{ color: "#868686" }}>
          Therefore, the primary goal of our review was to identify how we could
          create a network that helps connect First Nations communities to
          better access clean water and promote transparent relations between
          the Federal Government and Indigenous communities in Ontario, Canada.
        </i>
      </Fragment>
    ),
    researchText: (
      <Fragment>
        Our initial research process entailed each group member starting their
        research process separately in search of six to seven credible,
        reliable, academic articles that related to our topic of clean water and
        sanitation with a particular focus on the Indigenous peoples of Canada
        who are living on First Nations reserves. We found that completing the
        necessary research together would be most ideal, however, this wouldn’t
        be realistic as each group member had different availability. So in
        turn, we decided to implement a project plan to mitigate any issues we
        might run into.
        <br />
        <br />
        Our process for looking up sources was to first establish an extremely
        rough idea of what our final product will likely do, what services it
        would offer, and who it would be offered to, from there, we needed to
        evaluate “what” we needed to create in order to create the
        product/service we want.{" "}
        <i style={{ color: "#868686" }}>
          This initial brainstorming session is how we started to inaugurate
          what our final products would be, which is an application system that
          is linked with a water testing device. We had anticipated that the
          water tester would be easily accessible and very easy to use, and not
          only could it easily test and assess the pH level of the water, but
          the bacterial & mineral content as well as the chlorine concentration.
          These assessments and readings would all be sent back to the app,
          where resources and support will be provided. The location of any
          “potential high risk” drinking water will send a ping automatically in
          the app and any governing bodies or a corporation would be able to
          more easily see and target any problem areas.{" "}
        </i>
        With this in mind, we knew we needed more research on the social,
        environmental, and accessibility factors that affect the populations
        living on First Nations reserves. As well as strive to gain a better
        understanding of the needs of our target audience(s) and the viability
        of our product and if our consumers could actually utilize it.
        Additionally, we need to consider the technical specifications and
        limitations of the device given the present day technology during our
        research process. We used the Zotero database to easily view and
        supervise the academic journals that were being added, to avoid overlap
        and repetitiveness within journals.
        <br />
        <br />
        Our team strived to gather our sources for our literary review from the
        following databases, ProQuest, UWaterloo Library, PubMed, ScienceDirect,
        BioMed Central, Scopus, Dow Jones Factiva, Springer text, <nbsp></nbsp>
        <a
          alt=""
          href="https://storymaps.arcgis.com/"
          style={{ textDecoration: "none", color: "#525252" }}
        >
          https://storymaps.arcgis.com
        </a>
        , Taylor and Francis online, Laurentian.ca, and www.mdpi.com. The search
        criteria we used focused on our users and the organizations that could
        help or are already working towards helping the Indigenous water crisis
        on reserves. The keywords that were used to research and select our
        academic journals and articles were, Indigenous, First Nations, clean
        water, water, water crisis, water treatment, sewage, wastewater
        management, First Nations reserves, water clarity, drinking water, and
        water testing technology. After adding each of our research journals, we
        sorted them individually into two groups: Primary and secondary
        research.
        <br />
        <br />
        Spreadsheet containing primary and secondary sources: <nbsp></nbsp>
        <a
          alt=""
          href="https://docs.google.com/spreadsheets/d/1f_ymodK6fZ_TNJw_gEFQjmQ4rx2YRHt8QLpQu4r7NfY/edit?usp=sharing"
          style={{ textDecoration: "none", color: "#525252" }}
        >
          Spreadsheet
        </a>
        <br />
        <br />
        If you would like to see the literature review please email me at{" "}
        <i>
          <u>johnbreenbusiness@gmail.com</u>
        </i>
      </Fragment>
    ),
    solutionText: (
      <Fragment>
        We conducted an analysis of four different but related studies to grow
        our understanding of the issue of water safety and its impact on
        communities across Canada. In particular, the studies focused on First
        Nations communities in Ontario and their experience with water safety.
        Using the knowledge we gained about social, environmental and
        accessibility factors through our literature review, we came up with our
        final product Nibi, a water tester that allows users to test their PH,
        bacteria and mineral levels. Conducting the review allowed us to gain an
        understanding of the experiences of First Nation communities in relation
        to this issue and its significant effect on the lives of those living
        within these communities. We were also able to recognize gaps within the
        research and provide solutions for them through our application and for
        studies completed in the future. Our main motivation for this analysis
        was to address the lack of accessibility to safe drinking water in
        communities globally by allowing users to collect and send information
        regarding water quality within their community to individuals that are
        able to help such as non-profit or government organisations.
        <br />
        <br />
        The first study focused on guidelines on drinking water quality across
        Canadian provinces and territories. The report highlighted Canada’s need
        to conduct more research concerning water safety in rural and First
        Nation communities and the importance of taking steps to address this
        issue. Keeping this in mind, Nibi was designed to assist in creating a
        network of information that allows users to share data with individuals
        that can use it to help. By doing so, we are able to create a global
        database of water quality and safety issues faced by individuals. The
        second study discussed strategies used by First Nation communities in
        order to overcome the disparity of fresh drinking water. Within the
        report, the unequal attention and importance given to First Nation
        communities when it comes to dealing with water advisories is discussed.
        In order to combat this issue, we ensured that Nibi was an accessible
        tool that allows communities to inform the government of their
        particular issues with water quality. For example, on the application PH
        levels over 7 are highlighted orange and red, indicating a more
        heightened need for help in those areas and alerting the right people in
        order to provide the aid needed to those communities. The third study
        shared interviews focused on the experiences of individuals living in
        First Nation communities and their perspectives of how the issue has
        impacted them. Nibi aims to do the same by allowing individuals to share
        and highlight areas in need of help. In turn, this data can be used to
        recognize trends that may further assist in addressing water safety
        issues in First Nation communities. The forth study focused on sensors
        and services that can be used to address water quality problems. For
        instance, the study discussed how the sensors will allow users to
        collect information on their water quality and whether it's safe for
        consumption. This is also why the development of Nibi as a tool to
        collect data on water quality issues will be beneficial.
        <br />
        <br />
        One limitation that is important to note in regards to Nibi is the need
        for internet access. Due to this prerequisite for using the application,
        users without access to the internet are excluded from being able to
        utilise the product and benefit from it. To conclude, through this
        literature review, we were able to gain a better understanding of the
        issue at hand and its impact on First Nation communities. With an
        application such as Nibi that will gather informative data on the issue
        and allow communities globally to get the help needed through one simple
        product, it has the potential to have a significant impact on the lives
        of those affected by water safety issues.
      </Fragment>
    ),
    methodText: (
      <Fragment>
        We utilized the Wizard of Oz Method which corresponds to methods of
        evaluation, refinement and production. To gather context of why we used
        this method we have referred back to our literature review. “Our
        objective is to create a fictitious prototype with a heavily researched
        inquiry that will allow Indigenous peoples to measure the pH levels of
        water, bacteria, and mineral levels. The prototype will have a
        geological orientation.” By implementing this method, we are recording
        user reactions and valuable feedback which will help us determine if
        this theoretical prototype is required in our overall research question.
        Our final methodology included various participants: (i) the “Moderator”
        who lead the session face to face with each user, (ii) the “Wizard” who
        controlled the responses sent to the user via a chosen device, and (iii)
        the referred participant (who is deemed non-biased). The setting took
        place within a classroom at the University of Waterloo Stratford School
        of Interaction Design and Business. The equipment consisted of (i) a
        twenty-seven inch iMac Air with a high-fidelity Wizard of Oz prototype
        created in Figma and presented through Figma on an iPhone 11
        screen-size, (ii) a cup of water, (iii) a theoretical pH device, and
        (iv) a product design sketch.
        <br />
        <br />
        Before conducting our user research we thought a sample size of between
        four to five people was a well-justified sample size for testing our
        theoretical prototype. We knew our prototype was strong and therefore we
        would yield similar responses after numerous users. In terms of the
        process, the first action completed by the user was connecting the pH
        device to their mobile phone using Bluetooth (i). Once completed the
        prototype would notify the user when the device has been connected. The
        second action was for the user to place the pH device into the cup of
        water (ii). The third action was for the user to acknowledge the pH
        devices display. The fourth action was to remove the pH device from the
        cup of water and hold and press the “Track” button to transfer the water
        analysis to the mobile device. The fifth action was for the user to
        check their mobile application to see real-time data about their water
        analysis.
        <br />
        <br />
        This entire process was mirrored from the “Wizards” computer, allowing
        for the participants to gain a real-sense of the applications usability
        performance. Our method also included a testing protocol (Study Material
        (d)), this was in the form of a script which detailed what exactly we
        wanted to happen within the test, what data we were looking to collect,
        and how we planned to observe the entire experiment. Data collection was
        taken in the form of notes, where we gained insights from questionnaire
        responses in the form of open-ended questions that took place at the end
        of the study.
        <br />
        <br />
        Based on the data collected from the Wizard of Oz experiment, it appears
        that users were generally satisfied with the process of testing the
        water with the device. However, there were suggestions for improvements
        such as adding a reset button, a feature to take a photo of the location
        being tested, and collecting multiple readings in a given area to
        identify patterns.
        <br />
        <br />
        One potential impact of this device is that it could increase users'
        confidence in the safety and suitability of their drinking water. This
        is especially relevant for users who are concerned about the quality of
        water when traveling, such as when camping or staying at a cottage. The
        simplicity of the app may also encourage more users to test their water
        regularly. Some users noted that the track button and feature could be
        confusing or unnecessary, and suggested reducing the track time or
        automatically tracking the data on the app. In terms of changes to the
        user experience, users suggested adding more data sets (such as mineral
        content and bacteria levels) to the device and app, as well as color
        indicators and a sense of the overall data on the screen. In terms of
        the analysis of these findings, it is clear that users were generally
        satisfied with the process of using the water testing device. However,
        there are also suggestions for improvements that could enhance the user
        experience and provide more comprehensive information about the quality
        of the water being tested.
        <br />
        <br />
        There are several ways in which the methodology of the Wizard of Oz
        experiment could be adjusted to potentially affect the results. For
        example, the sample size of participants could be increased to provide a
        more representative sample of the population. Additionally, a control
        group could be included to compare the results of the water testing
        device and process to a reference standard. The experiment could also be
        conducted in different locations to test the device's performance in a
        range of water quality conditions. These adjustments to the methodology
        could potentially affect the results of the experiment, although it is
        not possible to predict exactly how without conducting the experiment
        with the modified methodology.
        <br />
        <br />
        In order for the findings of this experiment to be considered
        generalizable, it would be necessary to conduct further testing with a
        larger and more diverse sample of users. This could include testing with
        users from different geographic regions, age groups, and demographics,
        as well as users with varying levels of familiarity and experience with
        water testing devices and apps. By conducting testing with a larger and
        more representative sample of users, it would be possible to better
        understand how the findings of this experiment apply to a wider
        population. Additionally, it would be important to carefully consider
        and control for potential biases and confounding factors that could
        affect the results of the experiment.
      </Fragment>
    ),
    conclusionText: (
      <Fragment>
        One open ended question that arises from this study is whether there are
        other effective methods that can be used to gather data and insights on
        the experiences of Indigenous peoples in regards to access to clean
        drinking water on First Nations reserves. The empathy map and survey
        methods used in this study were effective in gathering valuable
        information, but there may be other methods that could provide
        additional insight. For example, a case study approach could be used to
        analyze the experiences of specific communities in more depth and
        detail. In terms of the scope of the study, future research could also
        look at other Indigenous communities beyond First Nations reserves in
        Canada. Indigenous peoples in other countries, such as the United States
        and Australia, also face challenges in accessing clean drinking water,
        and their experiences and perspectives could provide valuable insight
        and help to develop more effective solutions.
        <br />
        <br />
        Additionally, future research could also focus on the broader
        environmental and social impacts of the lack of access to clean drinking
        water on Indigenous communities. This could include examining the
        economic impacts of poor water quality on these communities, as well as
        the potential long-term health effects of exposure to contaminated
        water.
        <br />
        <br />
        Overall, the main recommendation for future research in this area is to
        continue to gather data and insights from Indigenous peoples themselves
        in order to develop solutions that truly meet their needs and address
        the challenges they face in accessing clean drinking water.
      </Fragment>
    ),
    introductionImage: NibiFirst,
    segwayImage: NibiSecond,
    challengeImage: NibiThird,
    solutionImage: NibiFour,
    methodImage: NibiFive,
    researchImage: NibiSix,
  },


];

// How many sections should be created?
const numberOfSections = 1;

// Create array of random numbers we'll use for the index
const sections = [...Array(numberOfSections)].map((e) => Math.random());

const SkillsOverview = ({ skills }) => {
  const [items, setItems] = useState(skills);

  return (
    <>
      <Reorder.Group axis="y" onReorder={setItems} values={items}>
        {items.map((item) => (
          <Item key={item} item={item} />
        ))}
      </Reorder.Group>
    </>
  );
};

const PortfolioBackground = () => {
  const { slug } = useParams(); // get the slug parameter from the URL
  const project = projects.find((project) => project.slug === slug);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div>
        <HeroContainer>
          <div className="App">
            {sections.map((id) => {
              return (
                <Section
                  key={id}
                  text={project.herotextOne}
                  textTwo={project.heroSubHeading}
                />
              );
            })}
          </div>
        </HeroContainer>
        {project.ndaDisclaimer && (
          <InsightContainer>
            <InsightSubHeading>{project.ndaDisclaimer}</InsightSubHeading>
          </InsightContainer>
        )}
        <HeroImageContainer>
        <HeroImage 
          src={project.slug === "Konrad" ? KonradOne : project.introductionImage} 
          alt={project.slug === "Konrad" ? "Konrad project" : "Project introduction"}
        />
      </HeroImageContainer>
        <IntroColumns>
          <Role>
            <RoleHeading>My Role:</RoleHeading>
            <RoleSubHeading>{project.role}</RoleSubHeading>
            <VisitHeading>Visit at:</VisitHeading>
            <VisitSubHeading>
              <a
                href={project.url}
                alt=""
                style={{ textDecoration: "none", color: "#FCFCFC" }}
              >
                {project.url}
              </a>
              <TimeHeading>Year</TimeHeading>
              <TimeSubHeading>{project.year}</TimeSubHeading>
            </VisitSubHeading>
          </Role>
          <About>
            <AboutHeading>{project.intro}</AboutHeading>
            <SkillsOverview skills={project.skills} />
          </About>
        </IntroColumns>
        
        {project.slug !== "Konrad" ? (
          <>
        <HeroImageContainer>
          <HeroImage src={project.segwayImage}></HeroImage>
        </HeroImageContainer>

        <InsightContainer>
          <InsightHeading>The Challenge</InsightHeading>
          <InsightSubHeading>{project.challengeText}</InsightSubHeading>
        </InsightContainer>
        <HeroImageContainer>
          <HeroImage src={project.challengeImage}></HeroImage>
        </HeroImageContainer>
        <InsightContainer>
          <InsightHeading>The Research</InsightHeading>
          <InsightSubHeading>{project.researchText}</InsightSubHeading>
        </InsightContainer>
      
        <HeroImageContainer>
          <HeroImage
            style={{ width: "70%" }}
            src={project.researchImage}
          ></HeroImage>
        </HeroImageContainer>
        <InsightContainer>
          <InsightHeading>The Solution</InsightHeading>
          <InsightSubHeading>{project.solutionText}</InsightSubHeading>
        </InsightContainer>
        <HeroImageContainer>
          <HeroImage src={project.solutionImage}></HeroImage>
        </HeroImageContainer>
       
        {project.slug === "GiddyUp" && (
        <InsightContainer>
          <InsightHeading>The Tech</InsightHeading>
          <InsightSubHeading>{project.techText}</InsightSubHeading>
        </InsightContainer>
      )}
      
      {project.slug === "Nibì" && (
        <InsightContainer>
          <InsightHeading>Wizard of Oz Method</InsightHeading>
          <InsightSubHeading>{project.methodText}</InsightSubHeading>
        </InsightContainer>
      )}

        <HeroImageContainer>
          <HeroImage
            style={{ width: "70%" }}
            src={project.methodImage}
          ></HeroImage>
        </HeroImageContainer>
        <InsightContainer>
          <InsightHeading>The Conclusion</InsightHeading>
          <InsightSubHeading>{project.conclusionText}</InsightSubHeading>
        </InsightContainer>
        </>
        ) : (
          <>
          <HeroImageContainer>
            <HeroImage src={project.segwayImage} alt="Konrad project" />
          </HeroImageContainer>
          <HeroImageContainer>
            <HeroImage src={project.challengeImage} alt="Konrad project" />
          </HeroImageContainer>
          <HeroImageContainer>
            <HeroImage src={project.solutionImage} alt="Konrad project" />
          </HeroImageContainer>
          <HeroImageContainer>
            <HeroImage src={project.methodImage} alt="Konrad project" />
          </HeroImageContainer>
          </>
           )}
        <FooterSection>
          <FooterHeading>
            <Link
              to={`/`}
              style={{
                textDecoration: "none",
                color: "inherit",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                "&:active": {
                  backgroundColor: "transparent",
                },
              }}
            >
              Back Home
            </Link>
          </FooterHeading>
        </FooterSection>
      </div>
    </>
  );
};

export default PortfolioBackground;
