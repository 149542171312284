import React, { useRef, useEffect, useState } from "react";

// Style-components
import { Container, Project, Footer, FooterHeading } from "./styles";

// Assets
import avatarImage from "../../assets/avatar.webp";
import xdBadge from "../../assets/Xd.svg";
import aigaBadge from "../../assets/AIGA.svg";
import uiBadge from "../../assets/UI.svg";

// Material UI Imports
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import SchoolIcon from "@mui/icons-material/School";
import RocketIcon from "@mui/icons-material/Rocket";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import IconButton from "@mui/material/IconButton";
import LockIcon from "@mui/icons-material/Lock";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import KonradOne from "../../assets/konradone.png";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";

import { Link } from "react-router-dom";

// Framer Motion
import { useInView } from "framer-motion";

let theme = createTheme();
theme = responsiveFontSizes(theme);

// Animate Elements on View
function Section({ children }) {
  const ref = useRef(null);
  const isInview = useInView(ref, { once: true, pauseOnExit: false });

  return (
    <section ref={ref}>
      <span
        style={{
          transform: isInview ? "none" : "translateX(-200px)",
          opacity: isInview ? 1 : 0,
          transition: "all 2s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      >
        {children}
      </span>
    </section>
  );
}

const projects = [
  {
    id: 1,
    avatar: avatarImage,
    slug: "Hānāpāna",
    date: "May 20, 2024",
    title: "Sunlife Insurance Capstone: Hānāpāna",
    description:
      "I led the development of Hānāpāna, an innovative solution addressing the critical mental health challenges faced by young women entering the corporate world. Our team designed and prototyped private wellness rooms equipped with guided diaphragmatic breathing exercises, creating a sanctuary for stress relief and productivity enhancement in the workplace. Working closely with Sun Life Financial and the University of Waterloo, we crafted a pilot program to validate our concept's impact on employee well-being and organizational performance. Our strategic partnership model aligns incentives between insurers, employers, and employees, potentially transforming corporate wellness initiatives. From conceptualization to financial modeling and pilot design, this project showcases my ability to develop comprehensive, data-driven solutions for complex business challenges. It demonstrates my skills in innovation, strategic partnerships, and creating measurable value in the rapidly evolving corporate wellness landscape.",
    videoElement:
      "https://firebasestorage.googleapis.com/v0/b/johnny-portfolio-2023.appspot.com/o/hanapana.mp4?alt=media&token=a2b75c55-6893-447d-b48f-427237444d25",
    clickable: "none",
    icon: <SchoolIcon />,
    label: "University Project",
    secondchipOpacity: "100",
    iconTwo: <LockPersonIcon color="active" />,
    labelTwo: "Non-disclosure agreement",
    chipColor: "#080d18",
    thirdchipOpacity: "0",
    iconThree: <OpenInNewIcon color="active" />,
    labelThree: "Research",
    chipLinkThree: "https://firebasestorage.googleapis.com/v0/b/johnny-portfolio-2023.appspot.com/o/BreathingResearch.pdf?alt=media&token=d615d648-1e4e-40db-aa0d-deeee0be9d3c",
    iconFour: <OpenInNewIcon color="active" />,
    labelFour: "Branding",
    chipLinkFour: "https://firebasestorage.googleapis.com/v0/b/johnny-portfolio-2023.appspot.com/o/branding.pdf?alt=media&token=d007cf04-3aae-43f6-b4ee-080d1d8f9530",
    lock: "100",
    thirdchipOpacity: "100",
    chipColor: "#637C3D",
  },
  {
    id: 2,
    avatar: avatarImage,
    slug: "Konrad",
    date: "April 23, 2023",
    title: "Konrad Group Summer Internship 2023",
    description:
      "At Konrad Group, I led a design sprint revolutionizing search for a global firm's knowledge system. Our AI-powered solution impressed the client, potentially impacting their worldwide workforce. This project showcases my ability to solve complex UX challenges with innovative design thinking.",
    imageElement: KonradOne, // Make sure to import this at the top of your file
    icon: <WorkOutlineIcon />,
    label: "Internship",
    iconTwo: <LockPersonIcon color="active" />,
    labelTwo: "Non-disclosure agreement",
    chipColor: "#080d18",
    thirdchipOpacity: "0",
    lock: "0",
  },
  {
    id: 3,
    avatar: avatarImage,
    slug: "GiddyUp",
    date: "April 9, 2023",
    title: "GiddyUp Sports",
    description:
      "I designed, developed, and built GiddyUp Sports from the ground up. My automated waiver pickup tool eliminates late-night stress for fantasy football managers, ensuring top player acquisition throughout the season. Users can build dream teams effortlessly and wake up to consistent fantasy success",
    videoElement:
      "https://firebasestorage.googleapis.com/v0/b/johnny-portfolio-2023.appspot.com/o/giddyup.mp4?alt=media&token=1d96a899-59fb-4681-9669-108c8835b4c9",
    icon: <RocketIcon />,
    label: "Product",
    secondchipOpacity: "100",
    iconTwo: <SportsEsportsIcon color="active" />,
    labelTwo: "Test",
    chipLink: "https://giddyupsports.com/",
    chipColor: "#7920f6",
    thirdchipOpacity: "0",
    lock: "0",
  },
  {
    id: 4,
    avatar: avatarImage,
    slug: "Nibì",
    date: "December 9, 2022",
    title: "Nibi: Everyone deserves clean water",
    description:
      "Nibi is a tool that allows Indigenous communities to test and monitor the quality of their water, and share the results with relevant authorities in order to address any issues and improve water safety.",
    videoElement:
      "https://firebasestorage.googleapis.com/v0/b/johnny-portfolio-2023.appspot.com/o/NibiFirst.mp4?alt=media&token=dc85d649-4072-413d-b2ef-1604b9c906e7",
    icon: <SchoolIcon />,
    label: "University Project",
    secondchipOpacity: "0",
    thirdchipOpacity: "0",
    lock: "0",
  },
  {
    id: 5,
    avatar: avatarImage,
    slug: "Ablaze",
    date: "October 25, 2022",
    title: "Ablaze",
    description:
      "A mobile application that uses machine learning to help users determine the likelihood of a natural wildfire in their local environment, and provides fast, and safe escape routes in the event of a wildfire.",
    videoElement:
      "https://firebasestorage.googleapis.com/v0/b/johnny-portfolio-2023.appspot.com/o/Ablaze.mp4?alt=media&token=b812670e-f4ea-4ed4-987f-d71c350ad422",
    clickable: "none",
    icon: <SchoolIcon />,
    label: "University Project",
    secondchipOpacity: "100",
    iconTwo: <HourglassBottomIcon color="active" />,
    labelTwo: "Coming soon",
    chipColor: "#080d18",
    thirdchipOpacity: "0",
    lock: "100",
  },
  {
    id: 6,
    avatar: avatarImage,
    slug: "test",
    date: "August 5, 2020",
    title: "Aimé Leon Dore",
    description:
      "Art direction project for Aimé Leon Dore, a fashion label based out of Queens, New York that takes particular inspiration from the personal roots of Italy and Greece.",
    videoElement:
      "https://firebasestorage.googleapis.com/v0/b/johnny-portfolio-2023.appspot.com/o/Aime_Leon_Dore.mp4?alt=media&token=bf33d0e8-836f-4be3-a038-f08ed661456f",
    clickable: "none",
    icon: <AutoAwesomeIcon />,
    label: "Side-Project",
    secondchipOpacity: "100",
    iconTwo: <ArrowOutwardIcon color="active" />,
    labelTwo: "Behance",
    chipLink: "https://www.behance.net/gallery/101339777/Aim-Leon-Dore",
    chipColor: "#205CF6",
    behanceBannerOne: aigaBadge,
    behanceBannerOneOpacity: "100",
    behanceBannerTwo: xdBadge,
    behanceBannerTwoOpacity: "100",
    behanceBannerThree: uiBadge,
    behanceBannerThreeOpacity: "100",
},
  {
    id: 7,
    avatar: avatarImage,
    slug: "test",
    date: "February 6, 2022",
    title: "GetLayered",
    description:
      "The leading application for building banners powered by your favourite NFT projects",
    videoElement:
      "https://firebasestorage.googleapis.com/v0/b/johnny-portfolio-2023.appspot.com/o/getlayered.mp4?alt=media&token=9528b396-b6da-4e9e-af9a-600c96ecce67",
    clickable: "none",
    icon: <RocketIcon />,
    label: "Product",
    secondchipOpacity: "100",
    iconTwo: <SportsEsportsIcon color="active" />,
    labelTwo: "Test",
    chipLink: "https://getlayered.io/",
    chipColor: "#7920f6",
    thirdchipOpacity: "0",
  },
  {
    id: 8,
    avatar: avatarImage,
    slug: "test",
    date: "August 18, 2021",
    title: "9021: Les Genäratives & Fine arts ",
    description:
      "With the ever-expanding horizon of authenticity and digital art, the founders of 9021 wanted to leave their mark on the blockchain. The foresight of 9021 was shared between two university students studying solidity, and user experience and wanted to take a user-centered approach to building a brand within this ecosystem.",
    videoElement:
      "https://firebasestorage.googleapis.com/v0/b/johnny-portfolio-2023.appspot.com/o/9021.mp4?alt=media&token=e8e4edc0-e440-49c1-ab1f-903fae418080",
    clickable: "none",
    icon: <RocketIcon />,
    label: "Product",
    secondchipOpacity: "0",
    thirdchipOpacity: "0",
  },

  {
    id: 9,
    avatar: avatarImage,
    slug: "test",
    date: "May 26, 2021",
    title: "Eat Well",
    description:
      "A mobile app that gives the consumer an opportunity to learn more about the ecological impacts of any given produce item. These statistics are shown through a simple scan of a product's barcode.",
    videoElement:
      "https://firebasestorage.googleapis.com/v0/b/johnny-portfolio-2023.appspot.com/o/EatWell.mp4?alt=media&token=7b8892e2-65eb-4ff9-9049-180dcd899d45",
    clickable: "none",
    icon: <SchoolIcon />,
    label: "University Project",
    secondchipOpacity: "100",
    iconTwo: <HourglassBottomIcon color="active" />,
    labelTwo: "Coming soon",
    chipColor: "#080d18",
    thirdchipOpacity: "0",
    lock: "100",
  },

  {
    id: 10,
    avatar: avatarImage,
    slug: "test",
    date: "May 20, 2021",
    title: "Re-Rack",
    description:
      "Re-Rack is a student-run website created to inform its generation on the issues created by the Fast Fashion industry.",
    videoElement:
      "https://firebasestorage.googleapis.com/v0/b/johnny-portfolio-2023.appspot.com/o/FastFashion.mp4?alt=media&token=b095ef0f-67e6-440e-aee3-3386b79513b1",
    clickable: "none",
    icon: <SchoolIcon />,
    label: "University Project",
    secondchipOpacity: "0",
    thirdchipOpacity: "0",
  },

  {
    id: 11,
    avatar: avatarImage,
    slug: "test",
    date: "July 14, 2019",
    title: "KBM",
    description: "A fun and interactive typography simulation website.",
    videoElement:
      "https://firebasestorage.googleapis.com/v0/b/johnny-portfolio-2023.appspot.com/o/type%20simulation.mp4?alt=media&token=9ea3621e-ea13-482a-b432-6b9f9e621d21",
    clickable: "none",
    icon: <AutoAwesomeIcon />,
    label: "Side-Project",
    secondchipOpacity: "100",
    iconTwo: <SportsEsportsIcon color="active" />,
    labelTwo: "Test",
    chipLink: "https://kbm.superhi.com/",
    chipColor: "#7920f6",
    thirdchipOpacity: "0",
  },

  {
    id: 12,
    avatar: avatarImage,
    slug: "test",
    date: "June 11, 2020",
    title: "Obey Alliance: International Gaming",
    description: "Website and branding re-design.",
    videoElement:
      "https://firebasestorage.googleapis.com/v0/b/johnny-portfolio-2023.appspot.com/o/TitleScreenREAL.mp4?alt=media&token=7aa9c2e1-e9c0-47b8-bb57-2c90e0543ee1",
    clickable: "none",
    icon: <AutoAwesomeIcon />,
    label: "Side-Project",
    secondchipOpacity: "100",
    iconTwo: <ArrowOutwardIcon color="active" />,
    labelTwo: "Behance",
    chipLink:
      "https://www.behance.net/gallery/98487145/Obey-Alliance-International-Gaming",
    chipColor: "#205CF6",
    thirdchipOpacity: "0",
  },

  {
    id: 13,
    avatar: avatarImage,
    // slug: "test",
    date: "January 19, 2018",
    title: "FA Hockey: Branding and Advertising",
    description:
      "The challenge was to represent this visually in a brand identity that reflected Jason Dill's personality, values, and aesthetic, but stood as an entity of its own.",
    videoElement:
      "https://firebasestorage.googleapis.com/v0/b/johnny-portfolio-2023.appspot.com/o/intromovie.mp4?alt=media&token=018325ea-69d8-499f-b1ea-d892f791fb92",
    clickable: "none",
    icon: <AutoAwesomeIcon />,
    label: "Side-Project",
    secondchipOpacity: "100",
    iconTwo: <ArrowOutwardIcon color="active" />,
    labelTwo: "Behance",
    chipLink: "https://www.behance.net/gallery/60489951/FA-HOCKEY-2018",
    chipColor: "#205CF6",
    thirdchipOpacity: "0",
  },

  // Add more objects for additional projects here
];

const ProjectSections = () => {
  // Declare a state variable to store the viewport width
  const [viewportWidth, setViewportWidth] = useState(0);

  // Use the useEffect hook to update the viewport width when the component mounts or the window is resized
  useEffect(() => {
    const handleResize = () => setViewportWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {projects.map((project) => (
        <Section key={project.date}>
          <Container>
            <Project project={project}>
              <Card
                sx={{
                  width: viewportWidth <= 1280 ? "80%" : "50%",
                  borderRadius: "20px",
                  boxShadow: "none",
                  backgroundColor: "#1E1E1E",
                  border: "1px solid rgba(255, 255, 255, 0.12)",
                }}
              >
                <CardHeader
                  avatar={
                    <Avatar alt="Johnny Breen" src={project.avatar}></Avatar>
                  }
                  subheader={
                    <Typography color="white">{project.date}</Typography>
                  }
                  action={
                    <IconButton sx={{ pointerEvents: "none" }}>
                      <LockIcon
                        sx={{
                          color: "#fff",
                          opacity: project.lock,
                        }}
                      />
                    </IconButton>
                  }
                />

                <Link
                  onClick={(event) => {
                    if (project.clickable === "none") {
                      event.preventDefault();
                    }
                  }}
                  to={`/project/${project.slug} `}
                  disabled={project.clickable === "none"}
                  style={{
                    pointerEvents: project.clickable,
                    touchAction: project.clickable,
                    textDecoration: "none",
                    color: "inherit",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    "&:active": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <CardActionArea>
  {project.slug === "Konrad" ? (
    <CardMedia
      component="img"
      alt={project.title}
      image={project.imageElement}
      height="100%"
    />
  ) : (
    <CardMedia
      component="video"
      alt="video"
      image={project.videoElement}
      type="video/mp4"
      autoPlay
      allow="autoPlay"
      playsInline
      loop
      muted
      height="100%"
    />
  )}
</CardActionArea>
                </Link>
                <div
                  style={{
                    display: viewportWidth <= 860 ? "none" : "flex",
                    float: "right",
                    width: viewportWidth <= 860 ? "0%" : "5%",
                    marginRight: viewportWidth <= 1040 ? "20%" : "12%",
                  }}
                >
                  <img
                    alt=""
                    src={project.behanceBannerOne}
                    style={{
                      marginRight: "10px",
                      opacity: project.behanceBannerOneOpacity,
                    }}
                  />
                  <img
                    alt=""
                    src={project.behanceBannerTwo}
                    style={{
                      marginRight: "10px",
                      opacity: project.behanceBannerTwoOpacity,
                    }}
                  />
                  <img
                    alt=""
                    src={project.behanceBannerThree}
                    style={{
                      marginRight: "10px",
                      opacity: project.behanceBannerThreeOpacity,
                    }}
                  />
                </div>
                <CardContent>
                <Stack direction="row" spacing={1}>
    {project.icon && project.label && (
      <Chip
        icon={project.icon}
        label={project.label}
        sx={{
          fontFamily: "Roboto",
          fontWeight: "400",
          backgroundColor: "#dadada",
          color: "#1E1E1E",
        }}
      />
    )}

    {project.iconTwo && project.labelTwo && (
      <Chip
        icon={project.iconTwo}
        label={project.labelTwo}
        component="a"
        href={project.chipLink}
        target="_blank"
        variant="outlined"
        clickable
        sx={{
          fontFamily: "Roboto",
          fontWeight: "400",
          backgroundColor: project.chipColor,
          color: "#dadada",
        }}
        style={{
          pointerEvents: "auto",
          opacity: project.secondchipOpacity,
        }}
      />
    )}

    {project.iconThree && project.labelThree && (
      <Chip
        icon={project.iconThree}
        label={project.labelThree}
        component="a"
        href={project.chipLinkThree}
        target="_blank"
        variant="outlined"
        clickable
        sx={{
          fontFamily: "Roboto",
          fontWeight: "400",
          backgroundColor: project.chipColor,
          color: "#dadada",
        }}
        style={{
          pointerEvents: "auto",
          opacity: project.thirdchipOpacity,
        }}
      />
    )}

    {project.iconFour && project.labelFour && (
      <Chip
        icon={project.iconFour}
        label={project.labelFour}
        component="a"
        href={project.chipLinkFour}
        target="_blank"
        variant="outlined"
        clickable
        sx={{
          fontFamily: "Roboto",
          fontWeight: "400",
          backgroundColor: project.chipColor,
          color: "#dadada",
        }}
        style={{
          pointerEvents: "auto",
          opacity: project.thirdchipOpacity,
        }}
      />
    )}
  </Stack>
                  <Typography
                    gutterBottom
                    variant="h3"
                    component="div"
                    sx={{
                      fontFamily: "Canela Regular",
                      paddingTop: "20px",
                      color: "#FCFCFC",
                    }}
                  >
                    {project.title}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: "300",
                      color: "#9f9f9f",
                    }}
                  >
                    {project.description}
                  </Typography>
                </CardContent>
              </Card>
            </Project>
          </Container>
        </Section>
      ))}
      <Footer>
        {" "}
        <FooterHeading>
          Johnny Breen: johnbreenbusiness@gmail.com <br />
          2024 - <em style={{ fontSize: "16px" }}>Custom-built by me</em>
        </FooterHeading>
      </Footer>
    </ThemeProvider>
  );
};

export default ProjectSections;
