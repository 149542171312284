import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Section = (props) => {
  const { text } = props;
  const { textTwo } = props;
  const [ref, inView] = useInView({
    threshold: 0.5,
    // triggerOnce: true
  });

  // Declare a state variable to store the viewport width
  const [viewportWidth, setViewportWidth] = useState(0);

  // Use the useEffect hook to update the viewport width when the component mounts or the window is resized
  useEffect(() => {
    const handleResize = () => setViewportWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Define headline animations
  const headline = {
    hidden: { opacity: 0, y: -15 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.25,
        type: "spring",
        damping: 100,
        mass: 4,
      },
    },
  };

  // Define paragraph animations
  const paragraph = {
    hidden: { opacity: 0, y: -15 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.5,
        type: "spring",
        damping: 100,
        mass: 4,
      },
    },
  };

  return (
    <motion.section
      ref={ref}
      style={{ margin: "2rem 0" }}
      // Set initial state to key "hidden"
      initial="hidden"
      // Toggle between "visible" and "hidden" keys when inView is true
      // These key names are passed down to child motion elements
      animate={inView ? "visible" : "hidden"}
    >
      <motion.h1
        variants={headline}
        style={{
          maxWidth: "800px",
          fontFamily: "Canela Light",
          // Use JavaScript to apply styles based on the viewport width
          fontSize: viewportWidth <= 777 ? "35px" : "50px",
          lineHeight: viewportWidth <= 777 ? "52px" : "72px",
          fontWeight: "300",
          textAlign: "center",
          mixBlendMode: "difference",
          paddingLeft: "10%",
          paddingRight: "10%",
        }}
      >
        {text} <em style={{ fontStyle: "normal", display: "inline" }}></em>
      </motion.h1>
      <motion.p
        variants={paragraph}
        style={{
          marginTop: "-10px",
          fontFamily: "Canela Thin",
          paddingTop: "0px",
          color: "#919197",
          fontSize: viewportWidth <= 391 ? "25px" : "30px",
          lineHeight: "34px",
          textAlign: "center",
        }}
      >
        {textTwo}
      </motion.p>
    </motion.section>
  );
};

export default Section;
