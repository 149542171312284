import styled from "styled-components";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

export const HeroContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100vw;
`;

export const HeroH1 = styled.h1`
  max-width: 800px;
  font-family: "Canela Light";
  font-size: 50px;
  line-height: 72px;
  font-weight: 300;
  text-align: center;
  mix-blend-mode: difference;
  color: "#fff";
`;

export const HeroBody = styled.h6`
  margin-top: -10px;
  font-family: "Canela Thin";
  padding-top: 0px;
  color: #919197;
  font-size: 22px;
  line-height: 34px;
  text-align: center;
`;

export const HeroImageContainer = styled.div`
  width: 100vw;
  vertical-align: middle;
  display: inline-block;
  text-align: center;
  margin-top: 100px;
`;

export const HeroImage = styled.img`
  width: 100vw;
  object-fit: cover;
  background-repeat: none;
  background-position: center;
`;

export const IntroColumns = styled.div`
  display: grid;
  overflow: hidden;
  width: 100vw;
  @media (max-width: 830px) {
    display: block;
  }
`;

export const Role = styled.div`
  grid-column: 1;
  padding: 50px;

  @media (max-width: 900px) {
    padding: 20px;
  }
`;

export const RoleHeading = styled.h2`
  font-family: "Canela Thin";
  font-size: 20px;
  color: #a5a5a5;
`;

export const RoleSubHeading = styled.h2`
  font-family: "Roboto";
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  @media (max-width: 900px) {
    font-size: 16px;
  }
`;

export const VisitHeading = styled(RoleHeading)``;

export const VisitSubHeading = styled(RoleSubHeading)``;

export const TimeHeading = styled(RoleHeading)``;

export const TimeSubHeading = styled(RoleSubHeading)``;

export const About = styled.div`
  grid-column: 2;
  padding: 50px;
  @media (max-width: 900px) {
    padding: 20px;
  }
`;

export const AboutHeading = styled.h2`
  font-family: "Roboto";
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  @media (max-width: 12884px) {
    font-size: 17px;
    width: 60vw;
  }
  @media (max-width: 900px) {
    font-size: 16px;
  }
  @media (max-width: 513px) {
    width: 80vw;
  }
`;

export const SecondaryImage = styled(HeroImage)``;

export const InsightContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: flex-start;
  padding-top: 100px;
  padding-left: 300px;
  padding-right: 300px;
  padding-bottom: 100px;

  @media (max-width: 1500px) {
    padding-left: 100px;
    padding-right: 100px;
  }

  @media (max-width: 820px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (max-width: 777px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
  }
`;

export const InsightHeading = styled.h2`
  font-family: "Canela Regular";
  font-weight: 300;
  font-size: 50px;
  padding-left: 25px;
  padding-right: 25px;
  @media (max-width: 1300px) {
    font-size: 40px;
  }
  @media (max-width: 777px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const InsightSubHeading = styled.h2`
  font-family: "Roboto";
  font-weight: 400;
  font-size: 19px;
  line-height: 25px;
  padding-left: 25px;
  padding-right: 25px;
  @media (max-width: 1300px) {
    font-size: 16px;
  }
  @media (max-width: 777px) {
    font-size: 16px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const Footer = styled.footer`
  display: grid;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  padding-bottom: 100px;
`;

export const SegmentContainer = styled.div`
  padding-top: 300px;

  @media (max-width: 391px) {
    padding-top: 100px;
  }
`;

export const SegmentContent = styled.div`
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  width: 100vw;
  @media (max-width: 750px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
export const SegmentRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
`;

export const SegmentItemOne = styled.div`
  width: 30%;
`;

export const SegmentItemOneImage = styled.div`
  position: relative;
  width: 50vw;
`;

export const SegmentItemTwo = styled.div`
  width: 34.77%;
`;

export const FooterSection = styled.footer`
  display: grid;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 200px;
  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: 391px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const FooterHeading = styled.h2`
  margin-top: -10px;
  font-family: "Canela Thin";
  padding-top: 0px;
  color: #919197;
  font-size: 40px;
  line-height: 34px;
  text-align: center;
  transition: color 0.5s ease-in;
  cursor: pointer;
  &:hover {
    color: white;
  }
`;
