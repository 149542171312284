import * as React from "react";

// Material UI Imports
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import HomeIcon from "@mui/icons-material/Home";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import SchoolIcon from "@mui/icons-material/School";
import MailIcon from "@mui/icons-material/Mail";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { Link } from "react-router-dom";

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      bigMobile: 391,
      tablet: 650,
      desktop: 900,
    },
  },
});

// Destinations
const actions = [
  {
    icon: <MailIcon />,
    name: "Contact",
    path: "mailto:johnbreenbusiness@gmail.com",
  },
  {
    icon: <SchoolIcon />,
    name: "Resume",
    path: "https://firebasestorage.googleapis.com/v0/b/johnny-portfolio-2023.appspot.com/o/JohnnyResume2024.pdf?alt=media&token=9eaed78c-eb68-40c6-9fa4-6d6e44bc2a1c",
  },
  // { icon: <AutoStoriesIcon />, name: "Library", path: "/library" },
  { icon: <HomeIcon />, name: "Home", path: "/" },
];

export default function BasicSpeedDial() {
  // const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: 0,
          transform: "translateZ(0px)",
          flexGrow: 1,
        }}
      >
        <SpeedDial
          ariaLabel="SpeedDial"
          FabProps={{
            sx: {
              bgcolor: "#FCFCFC",
              "&:hover": {
                bgcolor: "#FCFCFC",
              },
            },
          }}
          sx={{
            position: "absolute",
            bottom: theme.spacing(-3),
            right: {
              mobile: theme.spacing(-6),
              bigMobile: theme.spacing(-4),
              tablet: theme.spacing(2),
              desktop: theme.spacing(2),
            },
          }}
          direction="left"
          icon={<SpeedDialIcon sx={{ color: "black" }} />}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              component={
                action.path.startsWith("http") ||
                action.path.startsWith("mailto:")
                  ? "a"
                  : Link
              }
              to={action.path}
              href={action.path}
              target={
                action.path.startsWith("http") ||
                action.path.startsWith("mailto:")
                  ? "_blank"
                  : null
              }
              rel="noopener noreferrer"
            />
          ))}
        </SpeedDial>
      </Box>
    </ThemeProvider>
  );
}
