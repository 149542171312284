import * as React from "react";
import { useMotionValue, Reorder } from "framer-motion";
import { useRaisedShadow } from "./use-raised-shadow.ts";

interface Props {
  item: string;
}

export const Item = ({ item }: Props) => {
  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);

  return (
    <Reorder.Item
      value={item}
      id={item}
      style={{
        boxShadow,
        y,
        borderRadius: "10px",
        marginBottom: "10px",
        width: "50vw",
        padding: "20px 20px",
        background: "rgb(34, 34, 34)",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexShrink: "1",
        cursor: "grab",
      }}
    >
      <span>{item}</span>
    </Reorder.Item>
  );
};
