import React, { useState, useEffect, useRef } from "react";

// Framer Motion
import { motion, AnimatePresence, useInView } from "framer-motion";

// Assets
import torontoImg from "../../assets/toronto.jpeg";
import polandImg from "../../assets/Poland.png";

// Style-components
import { HeroText, HeroContainer, Span } from "./styles";

// Animate Elements on View
function Section({ children }) {
  const ref = useRef(null);
  const isInview = useInView(ref, { once: true });

  return (
    <section ref={ref}>
      <span
        style={{
          transform: isInview ? "none" : "translateX(-200px)",
          opacity: isInview ? 1 : 0,
          transition: "all 2s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      >
        {children}
      </span>
    </section>
  );
}

// On-Mouse Animation
const Hero = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showImage, setShowImage] = useState(false);
  const [showImage2, setShowImage2] = useState(false);

  // Declare a state variable to store the viewport width
  const [viewportWidth, setViewportWidth] = useState(0);

  // Use the useEffect hook to update the viewport width when the component mounts or the window is resized
  useEffect(() => {
    const handleResize = () => setViewportWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Section>
      <HeroContainer>
        <HeroText>
          <Span
            style={{ color: "#fcfcfc" }}
            onMouseEnter={() => setShowImage2(true)}
            onMouseLeave={() => setShowImage2(false)}
          >
            I'm Johnny Breen
          </Span>
          .<br /> A University of Waterloo honours graduate, who is dedicated to using my
          skills and knowledge to create user experiences that drive innovation,
          progress, and beauty in the{" "}
          <Span
            style={{ color: "#fcfcfc" }}
            onMouseEnter={() => setShowImage(true)}
            onMouseLeave={() => setShowImage(false)}
          >
            world
          </Span>
          .
          <br />
          <br />
          <br /> I'm also a 🐝 farmer.
        </HeroText>
        {/* Use the motion component to animate the image in and out */}
        <motion.img
          src={polandImg}
          alt="Toronto"
          initial={{ scale: 0 }}
          animate={{ scale: showImage ? 1 : 0 }}
          transition={{ duration: 0.7 }}
          style={{
            position: "absolute",
            right: 10,
            bottom: "30%",
            width: viewportWidth <= 1246 ? "0%" : "30%",

            zIndex: "10",
          }}
        />
        <AnimatePresence>
          {showImage2 && (
            <motion.p
              initial={{ y: "-100%", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: "-100%", opacity: 0 }}
              transition={{ duration: 0.7 }}
              style={{
                position: "absolute",
                right: "45%",
                top: viewportWidth <= 1460 ? "230px" : "250px",
                width: "600px",
                color: "#c3c3c3",
                fontSize: viewportWidth <= 1246 ? "0px" : "22px",
                fontStyle: "italic",
              }}
            >
              Hard work beats talent, when talent dosen't work hard.
            </motion.p>
          )}
        </AnimatePresence>
      </HeroContainer>
    </Section>
  );
};

export default Hero;
