import * as React from "react";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import imageListItemClasses from "@mui/material/ImageListItemBar";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      bigMobile: 391,
      tablet: 650,
      desktop: 900,
    },
  },
});

export default function Movies2022() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "grid",
          padding: "10px",
          gridTemplateColumns: {
            mobile: "repeat(1, 1fr)",
            bigMobile: "repeat(2, 1fr)",
            tablet: "repeat(3, 1fr)",
            desktop: "repeat(4, 1fr)",
          },
          [`& .${imageListItemClasses.root}`]: {
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
            <ImageListItemBar title={item.title} subtitle={item.author} />
          </ImageListItem>
        ))}
      </Box>
    </ThemeProvider>
  );
}

const itemData = [
  {
    img: "https://cdn.theatlantic.com/thumbor/ejTooLwzzACST_2mK-b9WLZmg1E=/0x0:2736x1539/1600x900/media/img/mt/2022/11/KO2_20210810_32520rCC_f/original.jpg",
    title: " Glass Onion: A Knives Out Mystery (2022)",
  },
  {
    img: "https://media.newyorker.com/photos/617726db3a9f1e32755a7aee/master/w_2560%2Cc_limit/Brody-Dispatch.jpg",
    title: "The French Dispatch (2021)",
  },
  {
    img: "https://m.media-amazon.com/images/M/MV5BMTg3MTIzNzM4MF5BMl5BanBnXkFtZTcwMDEzMjU2Mg@@._V1_FMjpg_UX700_.jpg",
    title: "Public Enemies (2009)",
  },
  {
    img: "https://m.media-amazon.com/images/M/MV5BMTMwODA1ODU2NV5BMl5BanBnXkFtZTcwMDcxNzI0NA@@._V1_.jpg",
    title: "Blow (2001)",
  },
  {
    img: "https://m.media-amazon.com/images/M/MV5BMTU2NjY4NjM2OF5BMl5BanBnXkFtZTgwNDcyMzIyMzI@._V1_.jpg",
    title: "Molly`s Game (2017)",
  },
  {
    img: "https://m.media-amazon.com/images/M/MV5BZDU1ZTZhMDAtNWNiMy00Mjg3LWIyYjEtZTVhNGU1YzgxNTQ0XkEyXkFqcGdeQXVyOTc5MDI5NjE@._V1_.jpg",
    title: "Caddyshack (1980)",
  },
  {
    img: "https://images.immediate.co.uk/production/volatile/sites/3/2022/09/Yellowstone-Kevin-Costner-524aca3.jpg",
    title: "YellowStone (2018)",
  },
  {
    img: "https://static01.nyt.com/images/2016/02/04/arts/bigshort-belowtheline1/bigshort-belowtheline1-superJumbo.jpg",
    title: "The Big Short (2015)",
  },
  {
    img: "https://cdn.vox-cdn.com/thumbor/wzIjy3LhaZZ139norSxkcAF3muc=/0x0:1920x800/1200x0/filters:focal(0x0:1920x800):no_upscale()/cdn.vox-cdn.com/uploads/chorus_asset/file/22121242/casino_royale_movie_screencaps.com_8493.jpg",
    title: "Casino Royale (2006)",
  },
  {
    img: "https://m.media-amazon.com/images/M/MV5BMzAxNmFkZmEtYzFhNS00NTIwLWI2NGQtYzIwMmJlMTJlMDA0XkEyXkFqcGdeQXVyMTEyMjM2NDc2._V1_FMjpg_UX1280_.jpg",
    title: "Bullet Train (2022)",
  },
  {
    img: "https://media.npr.org/assets/img/2021/10/06/2021-10-06-fac0cdaf444387160b9fb13a9d0a46580fa80a7c.jpg",
    title: " No Time to Die (2021)",
  },
  {
    img: "https://media.newyorker.com/photos/60f33884e264792dccdff179/master/w_2560%2Cc_limit/Brody-Pig.jpg",
    title: "Pig (2021)",
  },
  {
    img: "https://idsb.tmgrup.com.tr/ly/uploads/images/2021/12/07/thumbs/800x531/165569.jpg",
    title: "Don`t Look Up (2021)",
  },
];
