import React from "react";

// Style-components
import { HeaderNavigation, HeaderNavigationLinksDiv } from "./styles";

// Material UI Imports
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import BasicSpeedDial from "./SpeedDial";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";

// Assets
import avatarImage from "../../assets/avatar.jpeg";

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      bigMobile: 391,
      tablet: 650,
      desktop: 900,
    },
  },
});

const Navigation = () => {
  return (
    <ThemeProvider theme={theme}>
      <HeaderNavigation>
        <Stack direction="row" spacing={2}>
          <Link to="/">
            <IconButton>
              <Avatar
                alt="John Breen"
                src={avatarImage}
                sx={{
                  width: {
                    mobile: "0px",
                    bigMobile: "0px",
                    tablet: "80px",
                    desktop: "100px",
                  },
                  height: {
                    mobile: "0px",
                    bigMobile: "0px",
                    tablet: "80px",
                    desktop: "100px",
                  },
                }}
              />
            </IconButton>
          </Link>
        </Stack>
        <HeaderNavigationLinksDiv>
          <BasicSpeedDial />
        </HeaderNavigationLinksDiv>
      </HeaderNavigation>
    </ThemeProvider>
  );
};

export default Navigation;
