import styled from "styled-components";

export const Container = styled.div`
  display: block;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  padding-top: 150px;
  @media (max-width: 755px) {
    padding-top: 50px;
  }
`;

export const Project = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Footer = styled.footer`
  display: grid;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;

  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: 420px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

export const FooterHeading = styled.h2`
  font-family: "Canela Thin";
  padding-top: 0px;
  color: #919197;
  font-size: 20px;
  line-height: 34px;
  text-align: center;
  transition: color 0.5s ease-in;
  cursor: pointer;
  &:hover {
    color: white;
  }
  @media (max-width: 420px) {
    font-size: 16px;
  }
`;
