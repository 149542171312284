import styled from "styled-components";

export const HeaderNavigation = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 106px;
  padding-bottom: 100px;
  padding-left: 100px;
  padding-right: 100px;
  @media (max-width: 1460px) {
    padding-bottom: 50px;
  }
  @media (max-width: 591px) {
    padding-bottom: 50px;
  }
`;
export const HeaderNavigationH2 = styled.h2`
  font-size: 24px;
  font-weight: 300;
  font-family: "Canela Regular";
`;

export const HeaderNavigationLinksDiv = styled.div``;

export const HeaderNavigationLinks = styled.a`
  font-size: 18px;
  font-weight: 300;
  padding-left: 100px;
  font-family: "Canela Regular";
`;
