import React from "react";
import BasicTabs from "./MovieList";

// Style-components
import { Container, ContainerText } from "./styles";

const Movies = () => {
  return (
    <>
      <Container>
        <ContainerText>My Journey Through Cinema</ContainerText>
      </Container>
      <BasicTabs />
    </>
  );
};

export default Movies;
