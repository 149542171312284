import * as React from "react";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import imageListItemClasses from "@mui/material/ImageListItemBar";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      bigMobile: 391,
      tablet: 650,
      desktop: 900,
    },
  },
});

export default function Movies2020() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "grid",
          padding: "10px",
          gridTemplateColumns: {
            mobile: "repeat(1, 1fr)",
            bigMobile: "repeat(2, 1fr)",
            tablet: "repeat(3, 1fr)",
            desktop: "repeat(4, 1fr)",
          },
          [`& .${imageListItemClasses.root}`]: {
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
            <ImageListItemBar title={item.title} subtitle={item.author} />
          </ImageListItem>
        ))}
      </Box>
    </ThemeProvider>
  );
}

const itemData = [
  {
    img: "https://assets.vogue.com/photos/5d38c6d8df75de00098dd10b/4:3/w_2000,h_1500,c_limit/00-promo-once-upon-a-time-in-hollywood.jpg",
    title: " Once Upon a Time in Hollywood (2019)",
  },
  {
    img: "https://pyxis.nymag.com/v1/imgs/d27/29c/f2d44646bac39eab2efa827231bc73a813-21-baby-driver.jpg",
    title: "Baby Driver (2017)",
  },
  {
    img: "https://i.ytimg.com/vi/votcOf5cYCM/maxresdefault.jpg",
    title: "The Dark Knight (2008)",
  },
  {
    img: "https://s3.amazonaws.com/static.rogerebert.com/uploads/blog_post/primary_image/far-flung-correspondents/why-the-godfather-part-ii-is-the-best-of-the-trilogy/the-godfather-part-ii-1200-1200-675-675-crop-000000.jpg",
    title: "The Godfather Part II (1974)",
  },
  {
    img: "https://cdn.mos.cms.futurecdn.net/GNbc428Nd48gNSRbAfpHwB.jpg",
    title: "Saving Private Ryan (1998)",
  },
  {
    img: "https://www.brightwalldarkroom.com/wp-content/uploads/2020/10/christoph-waltz-inglourious-basterds.jpg",
    title: "Inglourious Basterds (2009)",
  },
  {
    img: "https://fangirlish.com/wp-content/uploads/2020/11/Django-Unchained.jpg",
    title: "Django Unchained (2012)",
  },
  {
    img: "https://www.femina.ch/assets/content/migration/main/interstellar-picture-5.jpg",
    title: "Interstellar (2014)",
  },
  {
    img: "https://static01.nyt.com/images/2022/10/21/arts/21glengarry-dogs-anniversary3/21glengarry-dogs-anniversary3-mobileMasterAt3x.jpg",
    title: "Reservoir Dogs (1992)",
  },
  {
    img: "https://www.thoughtco.com/thmb/gKe4IOgSkjlsX-j3CJNtrL46_KE=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/g-peck-questions-witness-in--to-kill-a-mockingbird--2038670-9c847539be3342dc895188fa82d2b583.jpg",
    title: "To Kill a Mockingbird (1962)",
  },
  {
    img: "https://mediaproxy.salon.com/width/1200/https://media.salon.com/2013/05/great_gatsby1.jpg",
    title: "The Great Gatsby (2013)",
  },
  {
    img: "https://occ-0-41-586.1.nflxso.net/dnm/api/v6/E8vDc_W8CLv7-yMQu8KMEC7Rrr8/AAAABVJgO06RKuruJpcyezdM43Ai2ZjvNDmtbnwUXVtvXVhhvpL0tvhr4s9e3j8UojFCLao5a7v8Dg5kti1vFKcA0ldZXWnnC03nBRIt.jpg?r=cbf",
    title: "Snatch (2000)",
  },
  {
    img: "https://media.npr.org/assets/img/2020/01/23/the-gentlemen-tg_05974_02_rgb_wide-7310e94849ea50a1b77f4ae579851ae23cc9236e.jpg",
    title: "The Gentlemen (2019)",
  },
  {
    img: "https://img.washingtonpost.com/rw/2010-2019/WashingtonPost/2015/02/10/Weekend/Images/Film_Review_Kingsman_The_Secret_Service-04e66.jpg",
    title: "Kingsman: The Secret Service (2014)",
  },
  {
    img: "https://m.media-amazon.com/images/M/MV5BMTU4OTc2ODI2Nl5BMl5BanBnXkFtZTcwMDM1NzEyNw@@._V1_.jpg",
    title: "Holes (2003)",
  },
  {
    img: "https://lwlies.com/wp-content/uploads/2017/06/donnie-brasco-al-pacino-johnny-depp.jpg",
    title: "Donnie Brasco (1997)",
  },
  {
    img: "https://www.indiewire.com/wp-content/uploads/2021/06/oceans-eleven.png?resize=2048,1231",
    title: "Oceans 11-13 (2001-2007)",
  },
  {
    img: "https://www.slantmagazine.com/wp-content/uploads/2010/08/film_shutterisland.jpeg",
    title: "Shutter Island (2010)",
  },
  {
    img: "https://i.natgeofe.com/n/52d164a9-f25a-4542-ac0a-742f94184191/88826.jpg?w=1272&h=990",
    title: "The Grand Budapest Hotel (2014)",
  },
  {
    img: "https://m.media-amazon.com/images/M/MV5BMjI3MjE3ODM3N15BMl5BanBnXkFtZTcwMjczNDE0NQ@@._V1_.jpg",
    title: "Austin Powers: International Man of Mystery (1997)",
  },
  {
    img: "https://i.insider.com/5a9ef76ab538153f008b46f2?width=1000&format=jpeg&auto=webp",
    title: "That `70s Show (1998)",
  },
  {
    img: "https://cdn.vox-cdn.com/thumbor/FFtg220CAi4Iilph_ewW4gaCClA=/0x0:2864x1200/1200x800/filters:focal(982x303:1440x761)/cdn.vox-cdn.com/uploads/chorus_image/image/57044477/blade3.0.jpg",
    title: "Blade Runner 2049 (2017)",
  },
  {
    img: "https://pyxis.nymag.com/v1/imgs/b96/9e0/0d34d159914af1eebb1f8e38474dc2f60e-29-la-la-land-review.2x.h473.w710.jpg",
    title: "La La Land (2016)",
  },
  {
    img: "https://people.com/thmb/j6ZkWo4i4RdfDZI14QGQX0G0_v0=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(749x0:751x2):format(webp)/Hugh-Grant-Says-He-Dreaded-Filming-Love-Actuallys-Now-Iconic-Dance-Scene-112322-46fafdbf69a24667af3f54b997711efd.jpg",
    title: "Love Actually (2003)",
  },
  {
    img: "https://cloudfront-eu-central-1.images.arcpublishing.com/thenational/DWSYMP3UFBAEDCBAB762UAE7SI.jpg",
    title: "Avatar (2009)",
  },
  {
    img: "https://occ-0-1361-990.1.nflxso.net/dnm/api/v6/E8vDc_W8CLv7-yMQu8KMEC7Rrr8/AAAABXqCinUMGXcqlIwgByyPr0GXQfKP5M9djkiaG7XCUJfsj7i49yFSMla2aTfZXYh2Rch4bLV0roBX6Hu8Dalv3IRACmtK1EVRmCez.jpg?r=784",
    title: "Mr. & Mrs. Smith (2005)",
  },
  {
    img: "https://www.highsnobiety.com/static-assets/thumbor/0hG9N68T9MIX1r3TxXY6C3wDYF8=/1600x1067/www.highsnobiety.com/static-assets/wp-content/uploads/2018/09/18154714/mid-90s-main.jpg",
    title: "Mid90s (2018)",
  },
  {
    img: "https://www.hollywoodreporter.com/wp-content/uploads/2018/11/scarface_-_h_-_1983.jpg?w=1296",
    title: "Scarface (1983)",
  },
  {
    img: "https://media.newyorker.com/photos/5da4a5c756dcd400082a63ba/master/w_2560%2Cc_limit/Brody-Parasite.jpg",
    title: "Parasite (2019)",
  },
  {
    img: "https://cdn.vox-cdn.com/thumbor/X8I3RfU0ML4EDDTNvhiq_MO5XHQ=/0x0:2027x1097/1200x800/filters:focal(852x64:1176x388)/cdn.vox-cdn.com/uploads/chorus_image/image/56644309/shapecover.0.jpg",
    title: "The Shape of Water (2017)",
  },
  {
    img: "https://variety.com/wp-content/uploads/2013/06/venice-captain-phillips.jpg?w=681&h=383&crop=1&resize=681%2C383",
    title: "Captain Phillips (2013)",
  },
  {
    img: "https://assets1.cbsnewsstatic.com/hub/i/2013/01/18/e804cc43-8bd0-11e2-9400-029118418759/costume_argo.jpg",
    title: "Argo (2012)",
  },
  {
    img: "https://cinemaautopsy.files.wordpress.com/2012/01/e2809ec3a9c3ade2809ec3a9e280a2e2809ec3a9c2bae2809ec3a7c2a5e2809ec3a4c3a4e2809ec3a9c2b0e2809ec3a7c2a7e2809ec3a9e289a5_rgb.jpg?w=1680",
    title: "Hugo (2011)",
  },
  {
    img: "https://m.media-amazon.com/images/M/MV5BMjAyMjUyNzg1Ml5BMl5BanBnXkFtZTgwNzAwMzg5MTE@._V1_.jpg",
    title: "Pulp Fiction (1994)",
  },
  {
    img: "https://media.vanityfair.com/photos/541c841b1019a3955fea0c58/master/w_2560%2Cc_limit/shawshank-redemption-20th-anniversary-01.jpg",
    title: "The Shawshank Redemption (1994)",
  },
  {
    img: "https://www.rollingstone.com/wp-content/uploads/2018/06/rs-18377-anchorman-1800-1387407909.jpg?w=1581&h=1054&crop=1",
    title: "Anchorman: The Legend of Ron Burgundy (2004)",
  },
  {
    img: "https://media.gq.com/photos/62fe906709572dc621b43e94/master/pass/MCDSUPE_EC053.jpeg",
    title: "Superbad (2007)",
  },
  {
    img: "https://assets.vogue.com/photos/5891ee190e6cdc8a1928cf6b/master/pass/img-21-jump-street_113913594562.jpg",
    title: "21 Jump Street (2012)",
  },
  {
    img: "https://m.media-amazon.com/images/M/MV5BMTg2NzYzOTE5Nl5BMl5BanBnXkFtZTcwMzIwNDYyNA@@._V1_.jpg",
    title: "Get Smart (2008)",
  },
  {
    img: "https://a.ltrbxd.com/resized/sm/upload/km/lx/mu/dl/the-love-guru-1200-1200-675-675-crop-000000.jpg?v=c3ba8c192a",
    title: "The Love Guru (2008)",
  },
  {
    img: "https://m.media-amazon.com/images/M/MV5BMTQ1Mzk4NjAxNF5BMl5BanBnXkFtZTgwOTIwNjMwMjE@._V1_.jpg",
    title: "The Hangover (2009)",
  },
  {
    img: "https://m.media-amazon.com/images/M/MV5BMTM3OTM4NDU1Nl5BMl5BanBnXkFtZTcwNzI2NjIzNA@@._V1_.jpg",
    title: "Talladega Nights: The Ballad of Ricky Bobby (2006)",
  },
  {
    img: "https://cdn.vox-cdn.com/thumbor/hTlovuJDKbVJZH6Qi5SqHM_iTBI=/0x0:1334x750/1200x800/filters:focal(586x276:798x488)/cdn.vox-cdn.com/uploads/chorus_image/image/66597337/MV5BNjVhYzRiNmQtOGIzMy00ODAwLTk5YzktNWVjYzU2NjdkYjU0XkEyXkFqcGdeQXVyNjc5NDMyMjE_._V1_.0.jpg",
    title: "Tommy Boy (1995)",
  },
  {
    img: "https://m.media-amazon.com/images/M/MV5BMTQwNzI4NzE5Ml5BMl5BanBnXkFtZTcwNTI1Njk1MQ@@._V1_.jpg",
    title: "Semi-Pro (2008)",
  },
  {
    img: "https://img.texasmonthly.com/2020/11/dazed-and-confused-melissa-maerz-book-linklater-2.jpg?auto=compress&crop=faces&fit=fit&fm=pjpg&ixlib=php-3.3.1&q=45",
    title: "Dazed and Confused (1993)",
  },
  {
    img: "https://m.media-amazon.com/images/M/MV5BMTMyNTU2MjY4Nl5BMl5BanBnXkFtZTcwNjQ4NjgyMw@@._V1_.jpg",
    title: "Harry Potter and the Prisoner of Azkaban (2004)",
  },
  {
    img: "https://www.gannett-cdn.com/-mm-/373bf084fc8f2d8b4972ace5fc1cc14e6cf4f27a/c=0-101-2112-1294/local/-/media/2016/02/12/USATODAY/USATODAY/635908499373279740-XXX-E-3SHOT-ZOOLANDER-07-MOV.jpg",
    title: "Zoolander (2001)",
  },
  {
    img: "https://a.ltrbxd.com/resized/sm/upload/4y/hr/ty/s0/the-internship-1200-1200-675-675-crop-000000.jpg?v=72e20ddaf9",
    title: "The Internship (2013)",
  },
  {
    img: "https://m.media-amazon.com/images/M/MV5BMTk1NzgxMDkzNV5BMl5BanBnXkFtZTcwMTE3NzQzNg@@._V1_.jpg",
    title: "The Big Year (2011)",
  },
  {
    img: "https://www.gannett-cdn.com/presto/2020/07/13/USAT/7685211f-453c-4e78-8ef0-2fe0deebe454-i_b0_wedding_badsummer_29.JPG",
    title: "Wedding Crashers (2005)",
  },
  {
    img: "https://images.immediate.co.uk/production/volatile/sites/3/2022/02/24303692-high_res-peaky-blinders-vi-91942e2.jpg",
    title: "Peaky Blinders (2013)",
  },
  {
    img: "https://thecinemaarchives.com/wp-content/uploads/2017/02/ghost-writer-image.jpg",
    title: "The Ghost Writer (2010)",
  },
  {
    img: "https://play-lh.googleusercontent.com/proxy/iumanv3OWu4Sou8BhNLRAp1r9Czscs4xT2kgyHlTMJiOMAD9ngFHaIwnsSh3LibB5OHqRri3ak89oPJ9ICkcj3Wbs2FqsC2ntCRkp_LwFePfYd4RI9PV=s1920-w1920-h1080",
    title: "Bridge to Terabithia (2007)",
  },
  {
    img: "https://www.hollywoodreporter.com/wp-content/uploads/2017/05/murder-on-the-orient-express-df-02573_r2_rgb_copy_-_h_2017.jpg?w=1296",
    title: "Murder on the Orient Express (2017)",
  },
];
