import React from "react";

// Global Variables
import { GlobalStyle } from "./styles";
import GlobalFonts from "./fonts/fonts";

// Components
import Navigation from "./components/Navigation";
import Hero from "./components/Hero";
import ProjectSections from "./components/Project-Sections";
import Movies from "./components/Movie";

import PortfolioBackground from "./components/Project-Background";

//Router
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

export default function App() {
  return (
    <>
      <Router>
        <GlobalStyle />
        <GlobalFonts />
        <Navigation />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/library" element={<Library />} />
          {/* Render the ProjectDetails component when the URL matches the pattern /project/:id */}
          <Route path="/project/:slug" element={<ProjectOne />} />
        </Routes>
      </Router>
    </>
  );
}

function Library() {
  return <Movies />;
}

function Home() {
  return (
    <>
      <Hero />
      <ProjectSections />
    </>
  );
}

function ProjectOne() {
  return <PortfolioBackground />;
}
