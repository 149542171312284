import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        background-color: #1C1C1C;
        color: #FCFCFC;
        overflow-x: hidden;


        ::-webkit-scrollbar {
        width: 12px;
        background-color: #1C1C1C;
        border: 1px solid rgba(255, 255, 255, 0.01)
        }

        ::-webkit-scrollbar-thumb {
        background-color: #FCFCFC;
        border-radius: 20px;
        }
    }
`;
