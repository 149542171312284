import styled from "styled-components";

export const HeroContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const HeroText = styled.h1`
  max-width: 1041px;
  font-family: "Canela Light";
  font-size: 65px;
  line-height: 72px;
  font-weight: 300;
  text-align: center;
  mix-blend-mode: difference;
  @media (max-width: 1460px) {
    font-size: 50px;
    line-height: 62px;
  }
  @media (max-width: 1245px) {
    font-size: 40px;
    line-height: 55px;
  }

  @media (max-width: 714px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (max-width: 591px) {
    max-width: 100vw;
    font-size: 22px;
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const Span = styled.span`
  border-bottom: 1px solid;
  border-radius: 0;
  color: #000;
  text-decoration: none;
  cursor: pointer;
  /* Add a margin-bottom property with a value of -1px */
  margin-bottom: -1px;
`;
