import { createGlobalStyle } from "styled-components";

import CanelaThin from "./Canela-Thin-Trial.otf";
import CanelaLight from "./Canela-Light.ttf";
import CanelaRegular from "./Canela-Regular-Trial.otf";
import CanelaMedium from "./Canela-Medium-Trial.otf";

export default createGlobalStyle`

     @font-face {
        font-family: 'Canela Thin';
        src: local('Canela Thin'), local('CanelaThin'),
        url(${CanelaThin}) format('woff2')
    }

    @font-face {
        font-family: 'Canela Light';
        src: local('Canela Light'), local('CanelaLight'),
        url(${CanelaLight}) format('woff2')
    }

    @font-face {
        font-family: 'Canela Regular';
        src: local('Canela Regular'), local('CanelaRegular'),
        url(${CanelaRegular}) format('woff')
    }
    @font-face {
        font-family: 'Canela Medium';
        src: local('Canela Medium'), local('CanelaMedium'),
        url(${CanelaMedium}) format('woff2')
    }
`;
