import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100vw;
`;

export const ContainerText = styled.h1`
  font-family: "Canela Light";
  font-size: 65px;
  line-height: 72px;
  font-weight: 300;
  text-align: center;
  @media (max-width: 1245px) {
    font-size: 40px;
    line-height: 55px;
    padding-left: 50px;
    padding-right: 50px;
  }
`;
